import {observer} from "mobx-react";

// hooks
import {useLzssStore} from "../../../../../../../../store/hooks";

// components
import {Divider, Grid, Typography} from "@mui/material";
import {GroupForm, Label, NormalText} from "../../../virusology/hcv/HcvViewCase.styled";

// lib
import {checkValueIsEmpty} from "../../../virusology/hcv/HcvViewCase.helper";
import {dateStringify} from "../../../../../../../../utils";
import React from "react";
import {Attachments} from "../../../virusology/hcv/common/attachments/Attachments";
import theme from "../../../../../../../../theme/theme";

export const Observations = observer(() => {
  const caseStore = useLzssStore();
  const {case: caseElement} = caseStore;

  if (!caseElement) {
    return <></>;
  }

  return (
    <>
      <Grid container mt={8}>
        <Grid item xs={12} mb={4}>
          <GroupForm>Skyrizi:</GroupForm>
        </Grid>
        <Grid container bgcolor={"white"} p={2}>
          <Label $size={'large'}>Wyniki Badań przed włączeniem Skyrizi</Label>
          <Grid item xs={12} mt={2}>
            <Label>Data włączenia leku Skyrizi</Label>
            <NormalText>
              {checkValueIsEmpty(dateStringify(caseElement.drugInitiationDate))}
            </NormalText>
          </Grid>
          {caseElement.lzsCharacter === 'postać osiowa' && <>
            <Grid mt={4} mb={4}>
              <Grid item xs={12}>
                <Label $isNoneCase={true}>KRYTERIA PsARC</Label>
                <NormalText>{checkValueIsEmpty(caseElement.criteriaPsarc4)}</NormalText>
              </Grid>
            </Grid>
            {caseElement.criteriaPsarc4 === 'das 28' && <Grid container gap={'20px'}>
              <Grid item xs={2}>
                <Label>WYNIK DAS 28</Label>
                <NormalText>{checkValueIsEmpty(caseElement.das28Result4)}</NormalText>
              </Grid>
              <Grid item xs={2}>
                <Label>WYNIK</Label>
                <NormalText>{checkValueIsEmpty(caseElement.result4)}</NormalText>
              </Grid>
              <Grid item>
                <Label>WYNIK skali</Label>
                <NormalText> &lt;2,6 - remisja <br/>
                  &le;3,2 – aktywność mała <br/>
                  &gt;3,2 i &le;5,1 – aktywność umiarkowana <br/>
                  &gt;5,1 – aktywność duża</NormalText>
              </Grid>
            </Grid>}
            {caseElement.criteriaPsarc4 === 'likert' && <><Grid container gap={'20px'} mt={4}>
              <Grid item xs={4}>
                <Label>Wynik w skali Likerta pacjent</Label>
                <NormalText>{checkValueIsEmpty(caseElement.likertPatient4)}</NormalText>
              </Grid>
              <Grid item xs={4}>
                <Label>Wynik w skali Likerta lekarz</Label>
                <NormalText>{checkValueIsEmpty(caseElement.likertDoctor4)}</NormalText>
              </Grid>
            </Grid>
              <Grid item xs={12} mt={4}>
                <Label>OGÓLNA OCENA STANU CHOROBY</Label>
                <NormalText>{checkValueIsEmpty(caseElement.generalAssessment4)}</NormalText>
              </Grid>
              <Grid container gap={'20px'} mt={4}>
                <Grid item xs={4}>
                  <Label>LICZBA BOLESNYCH STAWÓW</Label>
                  <NormalText>
                    {checkValueIsEmpty(caseElement.tenderJointsCount4)}
                  </NormalText>
                </Grid>
                <Grid item xs={4}>
                  <Label>LICZBA OBRZĘKNIĘTYCH STAWÓW</Label>
                  <NormalText>
                    {checkValueIsEmpty(caseElement.swollenJointsCount4)}
                  </NormalText>
                </Grid>
              </Grid>
            </>}
          </>}
          {caseElement.lzsCharacter === 'postać obwodowa' && <>
            <Grid container gap={'20px'} mt={4}>
              <Grid item xs={4}>
                <Label>podaj wynik w skali</Label>
                <NormalText>{checkValueIsEmpty(caseElement.resultScaleOption4)}</NormalText>
              </Grid>
              <Grid item xs={4}>
                <Label>WYNIK</Label>
                <NormalText>{checkValueIsEmpty(caseElement.resultScale4)}</NormalText>
              </Grid>
            </Grid>
            <Grid container gap={'20px'} mt={4}>
              <Grid item xs={4}>
                <Label>Stopień bólu kręgosłupa</Label>
                <NormalText>
                  {checkValueIsEmpty(caseElement.degreeOfBack4)}
                </NormalText>
              </Grid>
              <Grid item xs={4}>
                <Label>OGÓLNA OCENA STANU CHOROBY</Label>
                <NormalText>
                  {checkValueIsEmpty(caseElement.generalAssessment24)}
                </NormalText>
              </Grid>
            </Grid>
          </>}
          <Grid item xs={12} mt={4}>
            <Label>wynik w skali pasi</Label>
            <NormalText>{checkValueIsEmpty(caseElement.pasi4)}</NormalText>
          </Grid>
        </Grid>

        <Grid container bgcolor={"white"} p={2} mt={5}>
          <Label>Obserwacja na leku Skyrizi</Label>
          <Grid item xs={12} mt={2}>
            <Label>Interwały obserwacji</Label>
            <NormalText>
              {checkValueIsEmpty(caseElement.observationIntervals)}
            </NormalText>
          </Grid>
        </Grid>


        {caseElement.observations.map((observation, index) => (
          <Grid container mt={5} bgcolor={"white"} p={2} key={index}>
            <Grid item xs={12}>
              <Label $size={"large"} $mb={0}>
                {`Obserwacja ${index + 1}`}
              </Label>
            </Grid>
            <Grid item xs={12} mt={4} mb={4}>
              <Label>Monoterapia czy kombinacja z innym lekiem?</Label>
              <NormalText>{checkValueIsEmpty(observation.monotherapy)}</NormalText>
            </Grid>
            {observation.combinationWithMedicine.length > 0 && <Divider
              style={{width: "100%"}}
              variant={"fullWidth"}
              sx={{height: "2px", margin: '0 0 32px 0'}}
            />}
            {
              observation.combinationWithMedicine.map((el, idx) => (
                <Grid container key={idx}>
                  <Grid item xs={12} mb={2}>
                    <Label $size={"large"} $mb={0}>
                      {`Kombinacja ${idx + 1}`}
                    </Label>
                  </Grid>
                  <Grid container gap={2}>
                    <Grid item xs={12}>
                      <Label>Kombinacja Z jakim lekiem?</Label>
                      <NormalText>{checkValueIsEmpty(el.combinationDrugName)}</NormalText>
                    </Grid>
                    {el.otherCombinationDrug && el.combinationDrugName === 'Inne' && <Grid item xs={12}>
                      <Label>nazwa innego leku</Label>
                      <NormalText>{checkValueIsEmpty(el.otherCombinationDrug)}</NormalText>
                    </Grid>}
                    <Grid item xs={12}>
                      <Label>Dawka</Label>
                      <NormalText>{checkValueIsEmpty(el.combinationDose)}
                        {checkValueIsEmpty(el.combinationUnit === 'Inna jednostka' ? el.otherCombinationUnit : el.combinationUnit)}
                      </NormalText>
                    </Grid>
                    <Grid item xs={12}>
                      <Label>Zakres czasu przyjmowania</Label>
                      <Typography variant={'caption'} color={theme.colors.orange}>liczba
                        tygodni</Typography>
                      <NormalText>{checkValueIsEmpty(el.combinationDuration)}</NormalText>
                    </Grid>
                  </Grid>
                  <Divider
                    style={{width: "100%"}}
                    variant={"fullWidth"}
                    sx={{height: "2px", margin: "32px 0 0 0"}}
                  />
                </Grid>
              ))
            }
            <Grid item xs={12} mt={4}>
              <Label>Glikokortykosteroidy (GKS)</Label>
              <NormalText>{checkValueIsEmpty(observation.gks)}</NormalText>
            </Grid>
            {caseElement.lzsCharacter === 'postać osiowa' && <>
              <Grid mt={4} mb={4}>
                <Grid item xs={12}>
                  <Label $isNoneCase={true}>KRYTERIA PsARC</Label>
                  <NormalText>{checkValueIsEmpty(observation.criteriaPsarc)}</NormalText>
                </Grid>
              </Grid>
              {observation.criteriaPsarc === 'das 28' && <Grid container gap={'20px'}>
                <Grid item xs={2}>
                  <Label>WYNIK DAS 28</Label>
                  <NormalText>{checkValueIsEmpty(observation.das28Result)}</NormalText>
                </Grid>
                <Grid item xs={2}>
                  <Label>WYNIK</Label>
                  <NormalText>{checkValueIsEmpty(observation.result)}</NormalText>
                </Grid>
                <Grid item>
                  <Label>WYNIK skali</Label>
                  <NormalText> &lt;2,6 - remisja <br/>
                    &le;3,2 – aktywność mała <br/>
                    &gt;3,2 i &le;5,1 – aktywność umiarkowana <br/>
                    &gt;5,1 – aktywność duża</NormalText>
                </Grid>
              </Grid>}
              {observation.criteriaPsarc === 'likert' && <><Grid container gap={'20px'}>
                <Grid item xs={4}>
                  <Label>Wynik w skali Likerta pacjent</Label>
                  <NormalText>{checkValueIsEmpty(observation.likertPatient)}</NormalText>
                </Grid>
                <Grid item xs={4}>
                  <Label>Wynik w skali Likerta lekarz</Label>
                  <NormalText>{checkValueIsEmpty(observation.likertDoctor)}</NormalText>
                </Grid>
              </Grid></>}
              <Grid item xs={12} mt={4}>
                <Label>OGÓLNA OCENA STANU CHOROBY</Label>
                <NormalText>{checkValueIsEmpty(observation.generalAssessment)}</NormalText>
              </Grid>
              <Grid container gap={'20px'} mt={4}>
                <Grid item xs={4}>
                  <Label>LICZBA BOLESNYCH STAWÓW</Label>
                  <NormalText>
                    {checkValueIsEmpty(observation.tenderJointsCount)}
                  </NormalText>
                </Grid>
                <Grid item xs={4}>
                  <Label>LICZBA OBRZĘKNIĘTYCH STAWÓW</Label>
                  <NormalText>
                    {checkValueIsEmpty(observation.swollenJointsCount)}
                  </NormalText>
                </Grid>
              </Grid>
            </>}
            {caseElement.lzsCharacter === 'postać obwodowa' && <>
              <Grid container gap={'20px'} mt={4}>
                <Grid item xs={4}>
                  <Label>podaj wynik w skali</Label>
                  <NormalText>{checkValueIsEmpty(observation.resultScaleOption)}</NormalText>
                </Grid>
                <Grid item xs={4}>
                  <Label>WYNIK</Label>
                  <NormalText>{checkValueIsEmpty(observation.resultScale)}</NormalText>
                </Grid>
              </Grid>
              <Grid container gap={'20px'} mt={4}>
                <Grid item xs={4}>
                  <Label>Stopień bólu kręgosłupa</Label>
                  <NormalText>
                    {checkValueIsEmpty(observation.degreeOfBack)}
                  </NormalText>
                </Grid>
                <Grid item xs={4}>
                  <Label>OGÓLNA OCENA STANU CHOROBY</Label>
                  <NormalText>
                    {checkValueIsEmpty(observation.generalAssessment2)}
                  </NormalText>
                </Grid>
              </Grid>
            </>}

          </Grid>))}
      </Grid>
      <Grid container mt={8} mb={5}>
        <Grid item xs={12} mb={4}>
          <GroupForm>Wnioski:</GroupForm>
        </Grid>
        <Grid container bgcolor={"white"} p={2}>
          <Grid item xs={12}>
            <NormalText>
              {checkValueIsEmpty(caseElement.conclusions)}
            </NormalText>
          </Grid>
        </Grid>
        {caseElement.files && caseElement.files.length > 0 &&
          <Grid container bgcolor={"white"} p={2} mt={3}>
            <Grid item xs={12}>
              <Attachments
                title={'Zdjęcia przykładowych zmian skórnych lub obrazowania'}
                attachments={
                  caseElement.files
                }
              />
            </Grid>
          </Grid>}
      </Grid>
    </>
  );
});
