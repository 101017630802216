import {observer} from "mobx-react";

// hooks
import {useStage01ViewModel} from "./Stage01.vm";

// components
import {Grid, Typography} from "@mui/material";
import {Label} from "../../../../../../../../shared/ui/label/Label";
import {Input} from "../../../../../../../../shared/ui/input/Input";

// lib
import theme from "../../../../../../../../../theme/theme";
import {RadioForm} from "../../../../../stages/firstStage/FirstStage.styled";
import {RadioBox} from "../../../../../../../../shared/ui/radioBox/RadioBox";
import {checkEmptyNumber} from "../../../../../../../../../utils/checkEmptyNumber";
import {MultipleSelectString} from "../../../../../../../../shared/ui/multipleSelectString/MultipleSelectString";
import {comorbiditiesOptions, criteriaOptions} from "./Stage01.helper";

export const Stage01 = observer(() => {
  const {formik} = useStage01ViewModel();

  return (
    <Grid
      container
      maxWidth={"714px"}
      display={"flex"}
      mx={"auto"}
      my={4}
      component="form"
    >
      {formik.errors.area && formik.touched.area && (
        <Typography>{JSON.stringify(formik)}</Typography>
      )}
      <Grid item xs={12} alignSelf={"center"}>
        <Input
          maxLength={64}
          isRequired={true}
          size={"small"}
          label={"Tytuł opisu przypadku"}
          isUpperCase={true}
          name={"title"}
          borderColor={theme.colors.grey5}
          placeholderColor={theme.colors.grey4}
          colorLabel={theme.colors.grey1}
          background={theme.colors.white}
          value={formik.values.title}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder={"Wpisz tytuł opisu przypadku..."}
          isError={formik.touched.title && Boolean(formik.errors.title)}
        />
      </Grid>
      <Grid
        item
        xs={12}
        mt={2}
        justifyContent={"space-between"}
        pb={2}
        display={"flex"}
        flexDirection={"column"}
      >
        <Label
          size={"small"}
          isUpperCase={true}
          color={theme.colors.grey1}
          label={"KRYTERIA KLASYFIKACYJNE ŁUSZCZYCOWEGO ZAPALENIA STAWÓW na podstawie kryteriów CASPAR*"}
        />
        <RadioForm>
          <RadioBox
            isError={formik.touched.criteria && Boolean(formik.errors.criteria)}
            id={"criteria"}
            name={"criteria"}
            value={"tak"}
            checked={formik.values.criteria === "tak"}
            onChange={formik.handleChange}
          />
          <RadioBox
            isError={formik.touched.criteria && Boolean(formik.errors.criteria)}
            id={"criteria2"}
            name={"criteria"}
            value={"nie"}
            checked={formik.values.criteria === "nie"}
            onChange={(e) => {
              formik.handleChange(e)
              formik.setFieldValue('criteriaPoints', [])
              formik.setFieldValue('points', null)
            }}
          />
        </RadioForm>
        {formik.touched.criteria && Boolean(formik.errors.criteria) && formik.errors.criteria &&
          <Typography variant={'caption'} fontWeight={'500'} fontSize={'10px'}
                      color={theme.colors.red}>{formik.errors.criteria}</Typography>}
      </Grid>
      {formik.values.criteria === 'tak' &&
        <Grid item xs={12}>
          <Label
            isUpperCase={false}
            style={{marginBottom: '-16px', marginTop: '-8px'}}
            color={theme.colors.orange}
            size={"superSmall"}
            label={"Rozpoznanie ŁZS wymaga wyniku minimum 3 punktów"}
          />
          <Grid item xs={12} mt={2}>
            <MultipleSelectString
              size={"medium"}
              isRequired={true}
              defaultValue={"Wybierz"}
              isError={
                (formik.touched.criteriaPoints && Boolean(formik.errors.criteriaPoints)) || formik.values.points ? Number(formik.values.points) < 3 : false
              }
              setFieldValue={formik.setFieldValue}
              selectedValues={formik.values.criteriaPoints || []}
              elementName={"criteriaPoints"}
              options={criteriaOptions}
            />
          </Grid>
          {formik.values.criteriaPoints?.length > 0 && <Grid item xs={12} mb={2}>
            <Label color={theme.colors.grey1} size={"small"} label={"Razem"}/>
            <div style={{lineHeight: "14px", color: theme.colors.grey1}}>
              {formik.values.points || ""}{' '}PKT.
            </div>
          </Grid>}
        </Grid>
      }
      <Grid
        container
        justifyContent={"space-between"}
        alignContent={"stretch"}
        mt={2}
      >
        <Grid item xs={3}>
          <Input
            before={
              <Label
                isUpperCase={false}
                style={{margin: "8px 0 0"}}
                color={theme.colors.orange}
                size={"superSmall"}
                label={"3 znaki"}
              />
            }
            maxWidth={"72px"}
            isRequired={true}
            label={"Inicjały pacjenta"}
            isUpperCase={true}
            name={"initials"}
            type={"text"}
            maxLength={3}
            colorLabel={theme.colors.grey1}
            value={formik.values.initials ? formik.values.initials.toUpperCase() : ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz"}
            isError={
              formik.touched.initials &&
              Boolean(formik.errors.initials)
            }
          />
        </Grid>
        <Grid
          item
          justifyContent={"space-between"}
          height={"100%"}
          pb={2}
          display={"flex"}
          flexDirection={"column"}
        >
          <Label
            size={"small"}
            isUpperCase={true}
            color={theme.colors.grey1}
            label={"płeć"}
            isRequired={true}
          />
          <RadioForm>
            <RadioBox
              isError={formik.touched.gender && Boolean(formik.errors.gender)}
              id={"gender1"}
              name={"gender"}
              value={"kobieta"}
              checked={formik.values.gender === "kobieta"}
              onChange={formik.handleChange}
            />
            <RadioBox
              isError={formik.touched.gender && Boolean(formik.errors.gender)}
              id={"gender2"}
              name={"gender"}
              value={"mężczyzna"}
              checked={formik.values.gender === "mężczyzna"}
              onChange={formik.handleChange}
            />
            <RadioBox
              isError={formik.touched.gender && Boolean(formik.errors.gender)}
              id={"gender3"}
              name={"gender"}
              value={"inne"}
              checked={formik.values.gender === "inne"}
              onChange={formik.handleChange}
            />
          </RadioForm>
        </Grid>
        <Grid
          item
          justifyContent={"space-between"}
          display={"flex"}
          flexDirection={"column"}
        >
          <Label
            isRequired={true}
            style={{margin: "8px 0 4px 0"}}
            size={"small"}
            color={theme.colors.grey1}
            label={"Wiek"}
          />
          <Input
            isRequired={true}
            isUpperCase={true}
            name={"age"}
            type={"number"}
            onChangeCustom={() => formik.setFieldValue('age', null)}
            colorLabel={theme.colors.grey1}
            value={formik.values.age}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz"}
            isError={formik.touched.age && Boolean(formik.errors.age)}
          />
        </Grid>
      </Grid>
      <Grid container gap={"20px"} alignContent={"stretch"} mt={2}>
        <Grid item xs={3}>
          <Input
            maxWidth={"72px"}
            isRequired={true}
            label={"Masa ciała w kg"}
            isUpperCase={true}
            name={"weightKg"}
            type={"number"}
            maxLength={3}
            onChangeCustom={() => formik.setFieldValue('weightKg', null)}
            colorLabel={theme.colors.grey1}
            value={checkEmptyNumber(formik.values.weightKg)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz"}
            isError={formik.touched.weightKg && Boolean(formik.errors.weightKg)}
          />
        </Grid>
        <Grid item>
          <Input
            maxWidth={"72px"}
            isRequired={true}
            label={"Wzrost w cm"}
            isUpperCase={true}
            name={"heightCm"}
            type={"number"}
            maxLength={3}
            onChangeCustom={() => formik.setFieldValue('heightCm', null)}
            colorLabel={theme.colors.grey1}
            value={checkEmptyNumber(formik.values.heightCm)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz"}
            isError={formik.touched.heightCm && Boolean(formik.errors.heightCm)}
          />
        </Grid>
        <Grid item md={3} xs={12} mt={{xs: 2, md: 0}} mb={{xs: 1, md: 0}}>
          <Label color={theme.colors.grey1} size={"small"} label={"BMI"}/>
          <div style={{lineHeight: "2.25rem", color: theme.colors.grey1}}>
            {formik.values.bmi || ""}
          </div>
        </Grid>
      </Grid>
      <Grid mt={2} item xs={12}>
        <Input
          label={"Czas trwania łzs"}
          before={
            <Label
              isUpperCase={false}
              style={{margin: "8px 0 0"}}
              color={theme.colors.orange}
              size={"superSmall"}
              label={"W latach"}
            />
          }
          isUpperCase={true}
          maxWidth={"72px"}
          name={"durationLzs"}
          type={"number"}
          colorLabel={theme.colors.grey1}
          onChangeCustom={() => formik.setFieldValue('durationLzs', null)}
          value={checkEmptyNumber(formik.values.durationLzs)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          borderColor={theme.colors.grey5}
          placeholderColor={theme.colors.grey4}
          background={theme.colors.white}
          placeholder={"Wpisz"}
          isError={
            formik.touched.durationLzs && Boolean(formik.errors.durationLzs)
          }
        />
      </Grid>
      <Grid item xs={12} mt={2}>
        <MultipleSelectString
          size={"medium"}
          isRequired={true}
          defaultValue={"Wybierz"}
          isError={
            formik.touched.comorbidities && Boolean(formik.errors.comorbidities)
          }
          label={
            "Choroby towarzyszące"
          }
          // handleChange={handleComorbidities}
          // name={"comorbidities"}
          setFieldValue={formik.setFieldValue}
          selectedValues={formik.values.comorbidities || []}
          elementName={"comorbidities"}
          options={comorbiditiesOptions}
        />
      </Grid>
      {formik.values.comorbidities?.includes("Inne") && (
        <Grid item xs={12} mt={2}>
          <Input
            label={"Inne"}
            isRequired={true}
            isUpperCase={true}
            name={"comorbiditiesOther"}
            type={"textarea"}
            colorLabel={theme.colors.grey1}
            value={formik.values.comorbiditiesOther}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz"}
            isError={
              formik.touched.comorbiditiesOther &&
              Boolean(formik.errors.comorbiditiesOther)
            }
          />
        </Grid>
      )}
      <Grid item xs={12} mt={3}>
        <Typography
          variant={"h6"}
          color={theme.colors.grey1}
          textTransform={"uppercase"}
          fontWeight={700}
          marginBottom={2}
        >
          Wywiad
        </Typography>
      </Grid>
      {(formik.values.gender === 'kobieta' || formik.values.gender === 'inne') && <><Grid
        item
        xs={12}
        mb={2}
        justifyContent={"space-between"}
        pb={2}
        display={"flex"}
        flexDirection={"column"}
      >
        <Label
          size={"small"}
          isUpperCase={true}
          color={theme.colors.grey1}
          label={"Hormonalna terapia zastępcza (HTZ)"}
        />
        <RadioForm>
          <RadioBox
            isError={formik.touched.htz && Boolean(formik.errors.htz)}
            id={"htz"}
            name={"htz"}
            value={"tak"}
            checked={formik.values.htz === "tak"}
            onChange={formik.handleChange}
          />
          <RadioBox
            isError={formik.touched.htz && Boolean(formik.errors.htz)}
            id={"htz2"}
            name={"htz"}
            value={"nie"}
            checked={formik.values.htz === "nie"}
            onChange={formik.handleChange}
          />
        </RadioForm>
      </Grid>
        <Grid
          item
          xs={12}
          mb={2}
          justifyContent={"space-between"}
          pb={2}
          display={"flex"}
          flexDirection={"column"}
        >
          <Label
            size={"small"}
            isUpperCase={true}
            color={theme.colors.grey1}
            label={"Antykoncepcja hormonalna"}
          />
          <RadioForm>
            <RadioBox
              isError={
                formik.touched.contraception &&
                Boolean(formik.errors.contraception)
              }
              id={"contraception"}
              name={"contraception"}
              value={"tak"}
              checked={formik.values.contraception === "tak"}
              onChange={formik.handleChange}
            />
            <RadioBox
              isError={
                formik.touched.contraception &&
                Boolean(formik.errors.contraception)
              }
              id={"contraception2"}
              name={"contraception"}
              value={"nie"}
              checked={formik.values.contraception === "nie"}
              onChange={formik.handleChange}
            />
          </RadioForm>
        </Grid>
      </>}
      <Grid container display={"flex"} gap={"20px"} mb={2}>
        <Grid
          item
          justifyContent={"space-between"}
          pb={2}
          display={"flex"}
          flexDirection={"column"}
        >
          <Label
            size={"small"}
            isUpperCase={true}
            color={theme.colors.grey1}
            label={"Palenie tytoniu"}
            isRequired={true}
          />
          <RadioForm>
            <RadioBox
              isError={formik.touched.smoking && Boolean(formik.errors.smoking)}
              id={"smoking"}
              name={"smoking"}
              value={"tak"}
              checked={formik.values.smoking === "tak"}
              onChange={formik.handleChange}
            />
            <RadioBox
              isError={formik.touched.smoking && Boolean(formik.errors.smoking)}
              id={"smoking2"}
              name={"smoking"}
              value={"nie"}
              checked={formik.values.smoking === "nie"}
              onChange={formik.handleChange}
            />
          </RadioForm>
        </Grid>
        <Grid>
          <Input
            label={"liczba paczkolat"}
            isUpperCase={true}
            name={"packYears"}
            type={"number"}
            maxWidth={"72px"}
            onChangeCustom={() => formik.setFieldValue('packYears', null)}
            colorLabel={theme.colors.grey1}
            value={formik.values.packYears}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz"}
            isError={
              formik.touched.packYears && Boolean(formik.errors.packYears)
            }
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        mb={2}
        justifyContent={"space-between"}
        pb={2}
        display={"flex"}
        flexDirection={"column"}
      >
        <Label
          size={"small"}
          isUpperCase={true}
          color={theme.colors.grey1}
          label={"Łuszczyca"}
        />
        <RadioForm>
          <RadioBox
            isError={
              formik.touched.psoriasis && Boolean(formik.errors.psoriasis)
            }
            id={"psoriasis"}
            name={"psoriasis"}
            value={"tak"}
            checked={formik.values.psoriasis === "tak"}
            onChange={formik.handleChange}
          />
          <RadioBox
            isError={
              formik.touched.psoriasis && Boolean(formik.errors.psoriasis)
            }
            id={"psoriasis2"}
            name={"psoriasis"}
            value={"nie"}
            checked={formik.values.psoriasis === "nie"}
            onChange={formik.handleChange}
          />
        </RadioForm>
      </Grid>
      <Grid
        item
        xs={12}
        mb={2}
        justifyContent={"space-between"}
        pb={2}
        display={"flex"}
        flexDirection={"column"}
      >
        <Label
          size={"small"}
          isUpperCase={true}
          color={theme.colors.grey1}
          label={"Czynnik reumatoidalny w surowicy lub aCCP"}
        />
        <RadioForm>
          <RadioBox
            isError={formik.touched.accp && Boolean(formik.errors.accp)}
            id={"accp"}
            name={"accp"}
            value={"pozytywny"}
            checked={formik.values.accp === "pozytywny"}
            onChange={formik.handleChange}
          />
          <RadioBox
            isError={formik.touched.accp && Boolean(formik.errors.accp)}
            id={"accp2"}
            name={"accp"}
            value={"negatywny"}
            checked={formik.values.accp === "negatywny"}
            onChange={formik.handleChange}
          />
          <RadioBox
            isError={formik.touched.accp && Boolean(formik.errors.accp)}
            id={"accp3"}
            name={"accp"}
            value={"brak danych"}
            checked={formik.values.accp === "brak danych"}
            onChange={formik.handleChange}
          />
        </RadioForm>
      </Grid>
    </Grid>
  );
});
