import {Grid} from "@mui/material";
import {observer} from "mobx-react-lite";

// hooks
import {useLzsrStore} from "../../../../../../../../store/hooks";

// components
import {GroupForm, Label, NormalText, NormalTextDiv,} from "../../../virusology/hcv/HcvViewCase.styled";

// lib
import {checkValueIsEmpty} from "../../../virusology/hcv/HcvViewCase.helper";

export const PatientSection = observer(() => {
  const caseStore = useLzsrStore();
  const {case: caseElement} = caseStore;

  if (!caseElement) {
    return <></>;
  }

  return (
    <Grid container mt={5}>
      <Grid item xs={12} mb={4}>
        <GroupForm>Opis kliniczny:</GroupForm>
      </Grid>

      <Grid item xs={12}>
        <Grid container borderRadius={1} bgcolor={"white"} p={2}>
          <Grid gap={'20px'} container>
            <Grid item xs={12}>
              <Label>KRYTERIA KLASYFIKACYJNE ŁUSZCZYCOWEGO ZAPALENIA STAWÓW na podstawie kryteriów CASPAR</Label>
              <NormalText>{checkValueIsEmpty(caseElement.criteria)}</NormalText>
            </Grid>
            {caseElement.criteriaPoints && caseElement.criteriaPoints.length > 0 && <Grid item xs={12}>
              <Label>Rozpoznanie ŁZS</Label>
              <NormalText>
                {caseElement.criteriaPoints
                  .map(el => el.replace(/ – [12] pkt/g, ''))
                  .filter(el => el.trim() !== '')
                  .join(', ')}
              </NormalText>
            </Grid>}
            {!!caseElement.points && <Grid item xs={12}>
              <Label>Razem</Label>
              <NormalText>{checkValueIsEmpty(caseElement.points)}{' '}PKT.</NormalText>
            </Grid>}
            <Grid item xs={2}>
              <Label>Inicjały pacjenta</Label>
              <NormalText>{checkValueIsEmpty(caseElement.initials)}</NormalText>
            </Grid>
            <Grid item xs={1}>
              <Label>WIEK</Label>
              <NormalText>{checkValueIsEmpty(caseElement.age)}</NormalText>
            </Grid>
            <Grid item xs={2}>
              <Label>PŁEĆ</Label>
              <NormalText>
                {(checkValueIsEmpty(caseElement.gender))}
              </NormalText>
            </Grid>
            <Grid item xs={2}>
              <Label>Masa ciała w kg</Label>
              <NormalText>{caseElement.weightKg}</NormalText>
            </Grid>
            <Grid item xs={2}>
              <Label>Wzrost w cm</Label>
              <NormalText>{caseElement.heightCm}</NormalText>
            </Grid>
            <Grid item xs={1}>
              <Label>BMI</Label>
              <NormalText>{caseElement.bmi}</NormalText>
            </Grid>
          </Grid>
          {caseElement.durationLzs && <Grid item xs={12} mt={4}>
            <Label>Czas trwania łuszczycowego zapalenia stawów (łzs) w latach</Label>
            <NormalText>
              {checkValueIsEmpty(caseElement.durationLzs)}
            </NormalText>
          </Grid>}
          <Grid item xs={12} mt={4}>
            <Grid container borderRadius={1} bgcolor={"white"}>
              <Grid item xs={12}>
                <Label>
                  Choroby towarzyszące
                </Label>
                <NormalTextDiv>
                  {caseElement.comorbidities?.length > 0 && (
                    <ul>
                      <>
                        {caseElement.comorbidities.map((element) => (
                          <li key={element}>{element}</li>
                        ))}
                      </>
                    </ul>
                  )}
                </NormalTextDiv>
              </Grid>
            </Grid>
          </Grid>
          {caseElement.comorbiditiesOther && <Grid item xs={12} mt={2}>
            <Label>Inne</Label>
            <NormalText>
              {checkValueIsEmpty(caseElement.comorbiditiesOther)}
            </NormalText>
          </Grid>}
        </Grid>
      </Grid>
      <Grid item xs={12} mt={5}>
        <Grid container borderRadius={1} bgcolor={"white"} p={2}>
          <Grid item xs={12} mb={3}>
            <Label $size={"large"} $mb={0}>
              WYWIAD:
            </Label>
          </Grid>
          <Grid container gap={'20px'}>
            {(caseElement.gender === 'kobieta' || caseElement.gender === 'inne') && <>
              <Grid item xs={12}>
                <Label>HORMONALNA TERAPIA ZASTĘPCZA (HTZ)</Label>
                <NormalText>{checkValueIsEmpty(caseElement.htz)}</NormalText>
              </Grid>
              <Grid item xs={12}>
                <Label>Antykoncepcja hormonalna</Label>
                <NormalText>{checkValueIsEmpty(caseElement.contraception)}</NormalText>
              </Grid>
            </>}
            <Grid item xs={2}>
              <Label>PALENIE TYTONIU</Label>
              <NormalText>{checkValueIsEmpty(caseElement.smoking)}</NormalText>
            </Grid>
            <Grid item xs={2}>
              <Label>LICZBA PACZKOLAT</Label>
              <NormalText>
                {checkValueIsEmpty(caseElement.packYears)}
              </NormalText>
            </Grid>
            <Grid item xs={12}>
              <Label>ŁUSZCZYCA</Label>
              <NormalText>
                {checkValueIsEmpty(caseElement.psoriasis)}
              </NormalText>
            </Grid>
            <Grid item xs={12}>
              <Label>CZYNNIK REUMATOIDALNY W SUROWICY LUB ACCP</Label>
              <NormalText>{checkValueIsEmpty(caseElement.accp)}</NormalText>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});
