import {observer} from "mobx-react";

// hooks
import {useLzsrStore} from "../../../../../../../../store/hooks";

// components
import {Divider, Grid, Typography} from "@mui/material";
import {GroupForm, Label, NormalText} from "../../../virusology/hcv/HcvViewCase.styled";

// lib
import {checkValueIsEmpty} from "../../../virusology/hcv/HcvViewCase.helper";
import {dateStringify} from "../../../../../../../../utils";
import React from "react";
import theme from "../../../../../../../../theme/theme";

export const TreatmentProgramSection = observer(() => {
  const caseStore = useLzsrStore();
  const {case: caseElement} = caseStore;

  if (!caseElement) {
    return <></>;
  }

  return (
    <Grid container mt={8}>
      <Grid item xs={12} mb={4}>
        <GroupForm>Program lekowy:</GroupForm>
      </Grid>
      <Grid container bgcolor={"white"} p={2}>
        <Label $size={"large"}>Moment Włączenia do programu lekowego</Label>
        <Grid item xs={12} mt={2}>
          <Label>DATA WEJŚCIA DO PROGRAMU LEKOWEGO</Label>
          <NormalText>
            {checkValueIsEmpty(dateStringify(caseElement.programEntryDate))}
          </NormalText>
        </Grid>
        {caseElement.lzsCharacter === 'postać osiowa' && <>
          <Grid mt={4} mb={4}>
            <Grid item xs={12}>
              <Label $isNoneCase={true}>KRYTERIA PsARC</Label>
              <NormalText>{checkValueIsEmpty(caseElement.criteriaPsarc3)}</NormalText>
            </Grid>
          </Grid>
          {caseElement.criteriaPsarc3 === 'das 28' && <Grid container gap={'20px'} mb={4}>
            <Grid item xs={2}>
              <Label>WYNIK DAS 28</Label>
              <NormalText>{checkValueIsEmpty(caseElement.das28Result3)}</NormalText>
            </Grid>
            <Grid item xs={2}>
              <Label>WYNIK</Label>
              <NormalText>{checkValueIsEmpty(caseElement.result3)}</NormalText>
            </Grid>
            <Grid item>
              <Label>WYNIK skali</Label>
              <NormalText> &lt;2,6 - remisja <br/>
                &le;3,2 – aktywność mała <br/>
                &gt;3,2 i &le;5,1 – aktywność umiarkowana <br/>
                &gt;5,1 – aktywność duża</NormalText>
            </Grid>
          </Grid>}
          {caseElement.criteriaPsarc3 === 'likert' && <><Grid container gap={'20px'} mt={4}>
            <Grid item xs={4}>
              <Label>Wynik w skali Likerta pacjent</Label>
              <NormalText>{checkValueIsEmpty(caseElement.likertPatient3)}</NormalText>
            </Grid>
            <Grid item xs={4}>
              <Label>Wynik w skali Likerta lekarz</Label>
              <NormalText>{checkValueIsEmpty(caseElement.likertDoctor3)}</NormalText>
            </Grid>
          </Grid>
            <Grid item xs={12} mt={4}>
              <Label>OGÓLNA OCENA STANU CHOROBY</Label>
              <NormalText>{checkValueIsEmpty(caseElement.generalAssessment3)}</NormalText>
            </Grid>
            <Grid container gap={'20px'} mt={4}>
              <Grid item xs={4}>
                <Label>LICZBA BOLESNYCH STAWÓW</Label>
                <NormalText>
                  {checkValueIsEmpty(caseElement.tenderJointsCount3)}
                </NormalText>
              </Grid>
              <Grid item xs={4}>
                <Label>LICZBA OBRZĘKNIĘTYCH STAWÓW</Label>
                <NormalText>
                  {checkValueIsEmpty(caseElement.swollenJointsCount3)}
                </NormalText>
              </Grid>
            </Grid></>}
        </>}
        {caseElement.lzsCharacter === 'postać obwodowa' && <>
          <Grid container gap={'20px'} mt={4}>
            <Grid item xs={4}>
              <Label>podaj wynik w skali</Label>
              <NormalText>{checkValueIsEmpty(caseElement.resultScaleOption3)}</NormalText>
            </Grid>
            <Grid item xs={4}>
              <Label>WYNIK</Label>
              <NormalText>{checkValueIsEmpty(caseElement.resultScale3)}</NormalText>
            </Grid>
          </Grid>
          <Grid container gap={'20px'} mt={4} mb={4}>
            <Grid item xs={4}>
              <Label>Stopień bólu kręgosłupa</Label>
              <NormalText>
                {checkValueIsEmpty(caseElement.degreeOfBack3)}
              </NormalText>
            </Grid>
            <Grid item xs={4}>
              <Label>OGÓLNA OCENA STANU CHOROBY</Label>
              <NormalText>
                {checkValueIsEmpty(caseElement.generalAssessment23)}
              </NormalText>
            </Grid>
          </Grid>
        </>}
        <Grid item xs={12}>
          <Label>wynik w skali pasi</Label>
          <NormalText>{checkValueIsEmpty(caseElement.pasi)}</NormalText>
        </Grid>
      </Grid>

      {caseElement.treatmentLines.map((drug, index) => (
        <Grid container mt={5} bgcolor={"white"} p={2} key={index}>
          <Grid item xs={12} mb={2}>
            <Label $size={"large"} $mb={0}>
              {`Linia leczenia ${index + 1}`}
            </Label>
          </Grid>
          <Grid container>
            <Grid item xs={3}>
              <Label>WARIANT</Label>
              <NormalText>{checkValueIsEmpty(drug.variants)}</NormalText>
            </Grid>

            {drug.tnfInhibitorType && <Grid item xs={12} mt={2}>
              <Label>Rodzaj inhibitora TNF</Label>
              <NormalText>
                {checkValueIsEmpty(drug.tnfInhibitorType)}
              </NormalText>
            </Grid>}
            {drug.jakInhibitorType && <Grid item xs={12} mt={2}>
              <Label>Rodzaj inhibitora JAK</Label>
              <NormalText>
                {checkValueIsEmpty(drug.jakInhibitorType)}
              </NormalText>
            </Grid>}
            {drug.il17InhibitorType && <Grid item xs={12} mt={2}>
              <Label>Rodzaj inhibitora IL-17</Label>
              <NormalText>
                {checkValueIsEmpty(drug.il17InhibitorType)}
              </NormalText>
            </Grid>}
            {drug.il23InhibitorType && <Grid item xs={12} mt={2}>
              <Label>Rodzaj inhibitora II-23</Label>
              <NormalText>
                {checkValueIsEmpty(drug.il23InhibitorType)}
              </NormalText>
            </Grid>}
            <Grid item xs={12} mt={4}>
              <Label>Monoterapia czy kombinacja z innym lekiem?</Label>
              <NormalText>{checkValueIsEmpty(drug.monotherapy)}</NormalText>
            </Grid>
            {drug.combinationWithMedicine.length > 0 && <Divider
              style={{width: "100%"}}
              variant={"fullWidth"}
              sx={{height: "2px", margin: '32px 0 32px 0'}}
            />}
            {drug.combinationWithMedicine.map((el, idx) => (
              <Grid container key={idx}>
                <Grid item xs={12} mb={2}>
                  <Label $size={"large"} $mb={0}>
                    {`Kombinacja ${idx + 1}`}
                  </Label>
                </Grid>
                <Grid container gap={2}>
                  <Grid item xs={12}>
                    <Label>Kombinacja Z jakim lekiem?</Label>
                    <NormalText>{checkValueIsEmpty(el.combinationDrugName)}</NormalText>
                  </Grid>
                  {el.otherCombinationDrug && el.combinationDrugName === 'Inne' && <Grid item xs={12}>
                    <Label>nazwa innego leku</Label>
                    <NormalText>{checkValueIsEmpty(el.otherCombinationDrug)}</NormalText>
                  </Grid>}
                  <Grid item xs={12}>
                    <Label>Dawka</Label>
                    <NormalText>{checkValueIsEmpty(el.combinationDose)}
                      {checkValueIsEmpty(el.combinationUnit === 'Inna jednostka' ? el.otherCombinationUnit : el.combinationUnit)}
                    </NormalText>
                  </Grid>
                  <Grid item xs={12}>
                    <Label>Zakres czasu przyjmowania</Label>
                    <Typography variant={'caption'} color={theme.colors.orange}>liczba
                      tygodni</Typography>
                    <NormalText>{checkValueIsEmpty(el.combinationDuration)}</NormalText>
                  </Grid>
                </Grid>
                <Divider
                  style={{width: "100%"}}
                  variant={"fullWidth"}
                  sx={{height: "2px", margin: "32px 0 0 0"}}
                />
              </Grid>
            ))
            }
            <Grid item xs={12} mt={4}>
              <Label>Glikokortykosteroidy (GKS)</Label>
              <NormalText>{checkValueIsEmpty(drug.gks)}</NormalText>
            </Grid>
            {caseElement.lzsCharacter === 'postać osiowa' && <>
              <Grid mb={4} mt={4}>
                <Grid item xs={12}>
                  <Label $isNoneCase={true}>KRYTERIA PsARC</Label>
                  <NormalText>{checkValueIsEmpty(drug.criteriaPsarc3followup)}</NormalText>
                </Grid>
              </Grid>
              {drug.criteriaPsarc3followup === 'das 28' && <Grid container gap={'20px'}>
                <Grid item xs={2}>
                  <Label>WYNIK DAS 28</Label>
                  <NormalText>{checkValueIsEmpty(drug.das28Result3followup)}</NormalText>
                </Grid>
                <Grid item xs={2}>
                  <Label>WYNIK</Label>
                  <NormalText>{checkValueIsEmpty(drug.result3followup)}</NormalText>
                </Grid>
                <Grid item>
                  <Label>WYNIK skali</Label>
                  <NormalText> &lt;2,6 - remisja <br/>
                    &le;3,2 – aktywność mała <br/>
                    &gt;3,2 i &le;5,1 – aktywność umiarkowana <br/>
                    &gt;5,1 – aktywność duża</NormalText>
                </Grid>
              </Grid>}
              {drug.criteriaPsarc3followup === 'likert' && <><Grid container gap={'20px'} mt={4}>
                <Grid item xs={4}>
                  <Label>Wynik w skali Likerta pacjent</Label>
                  <NormalText>{checkValueIsEmpty(drug.likertPatient3followup)}</NormalText>
                </Grid>
                <Grid item xs={4}>
                  <Label>Wynik w skali Likerta lekarz</Label>
                  <NormalText>{checkValueIsEmpty(drug.likertDoctor3followup)}</NormalText>
                </Grid>
              </Grid>
                <Grid item xs={12} mt={4}>
                  <Label>OGÓLNA OCENA STANU CHOROBY</Label>
                  <NormalText>{checkValueIsEmpty(drug.generalAssessment3followup)}</NormalText>
                </Grid>
                <Grid container gap={'20px'} mt={4}>
                  <Grid item xs={4}>
                    <Label>LICZBA BOLESNYCH STAWÓW</Label>
                    <NormalText>
                      {checkValueIsEmpty(drug.tenderJointsCount3followup)}
                    </NormalText>
                  </Grid>
                  <Grid item xs={4}>
                    <Label>LICZBA OBRZĘKNIĘTYCH STAWÓW</Label>
                    <NormalText>
                      {checkValueIsEmpty(drug.swollenJointsCount3followup)}
                    </NormalText>
                  </Grid>
                </Grid></>}
            </>}
            {caseElement.lzsCharacter === 'postać obwodowa' && <>
              <Grid container gap={'20px'} mt={4}>
                <Grid item xs={4}>
                  <Label>podaj wynik w skali</Label>
                  <NormalText>{checkValueIsEmpty(drug.resultScaleOption3followup)}</NormalText>
                </Grid>
                <Grid item xs={4}>
                  <Label>WYNIK</Label>
                  <NormalText>{checkValueIsEmpty(drug.resultScale3followup)}</NormalText>
                </Grid>
              </Grid>
              <Grid container gap={'20px'} mt={4}>
                <Grid item xs={4}>
                  <Label>Stopień bólu kręgosłupa</Label>
                  <NormalText>
                    {checkValueIsEmpty(drug.degreeOfBack3followup)}
                  </NormalText>
                </Grid>
                <Grid item xs={4}>
                  <Label>OGÓLNA OCENA STANU CHOROBY</Label>
                  <NormalText>
                    {checkValueIsEmpty(drug.generalAssessment23followup)}
                  </NormalText>
                </Grid>
              </Grid>
            </>}
          </Grid>
          <Grid item xs={12} mt={4}>
            <Label>Czy były objawy nietolerancji wybranego leku?</Label>
            <NormalText>{checkValueIsEmpty(drug.symptomsOfIntolerance)}</NormalText>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
});
